import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import Cookie from '../../../images/cookie.svg'
import * as styles from './styles.module.scss'

const CookieNotice = props => (
  <CookieConsent
    containerClasses={styles.container}
    buttonClasses={styles.button}
    contentClasses={styles.content}
    disableStyles={true}
    acceptOnScroll={true}
    {...props}
  >
    <img alt='cookie' className={styles.cookie} src={Cookie} />
    <span>
      In order to optimize our website and to continually improve it for you, we
      use cookies. By continuing to use the website, you consent to the use of
      cookies. Further information on cookies can be found in our{' '}
      <Link className='inline' to='/privacy_policy'>
        Privacy Policy
      </Link>
    </span>
  </CookieConsent>
)

export default CookieNotice
